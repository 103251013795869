import { loginRequest } from "./authConfig.js";
export * from "@azure/msal-react";
export * from "./authConfig.js";

export const handleLogin = async (email, setLoading, instance) => {
  try {
    setLoading(true);
    localStorage.setItem("loading", "true");
    await instance.loginRedirect(loginRequest(email));
  } catch (error) {
    console.error(error);
  }
};

export const handleLogout = async (account, setLoading, instance) => {
  const logoutRequest = {
    account: instance.getAccountByHomeId(account),
  };
  try {
    setLoading(true);
    localStorage.clear();
    await instance.logoutRedirect(logoutRequest);
    sessionStorage.clear();
  } catch (error) {
    console.error(error);
  }
};
