import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    authority: process.env.AUTHORITY,
    clientId: process.env.CLIENT_ID,
    postLogoutRedirectUri: process.env.PUBLIC_AVE_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage",
    storeAuthStateInCookie: false,
  },
};

export const scopeApi = [process.env.SCOPE_API];
export const scopeUserRead = ["User.Read"];

export const loginRequest = (email) => {
  return {
    scopes: ["User.Read"],
    loginHint: email,
  };
};

export const msalInstance = new PublicClientApplication(msalConfig);
